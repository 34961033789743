<template>
  <div class="loginDiv" @keydown.enter="login">
    <div class="topDiv clearfix">
      <div class="leftlogo">
        <img src="../assets/images/logoTips.png" alt />
        <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">
          鄂ICP备2020022570号-1
        </a>
      </div>
      <div class="loginFormDiv" v-if="showDiv == 1">
        <img src="../assets/images/login_card.png" class="bgImg" />
        <div class="init">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/loginNameImg.png" />
            </div>
            <input placeholder="请输入账号" v-model="loginForm.userName" maxlength="16" />
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/loginPwdImg.png" />
            </div>
            <input placeholder="请输入密码" v-model="loginForm.password" type="password" maxlength="16" />
          </div>
          <div class="item">
            <div class="icon clearfix">
              <img src="../assets/images/loginCodeImg.png" />
            </div>
            <input placeholder="请输入验证码" class="iptLen" v-model="loginForm.passcode" maxlength="4" />
            <div class="codeImg finger">
              <img v-if="codeUrl" :src="codeUrl" @click="get_code" alt />
            </div>
          </div>
          <div class="choiceBox item">
            <span @click="checkClick">
              <img v-if="!check" src="../assets/images/checkBox1.png" />
              <img v-if="check" src="../assets/images/checkBox2.png" />记住账号
            </span>
            <!--            <span @click="forgetPsd">忘记密码？</span>-->
          </div>
          <div class="item loginBtn finger" @click="login"></div>
          <div class="txt">
            推荐使用谷歌浏览器，
            <span class="txtSpan" @click="downChrome">请点击下载</span>!
          </div>
        </div>
      </div>
      <div class="loginFormDiv forGetDiv" v-if="showDiv == 2">
        <img src="../assets/images/login_card2.png" class="bgImg" />
        <div class="init">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/loginNameImg.png" />
            </div>
            <input placeholder="请输入账号" v-model="forgetFrom.userName" maxlength="16" />
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/loginPwdImg.png" />
            </div>
            <input placeholder="请输入新密码" v-model="forgetFrom.password" type="password" maxlength="16" />
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/loginPwdImg.png" />
            </div>
            <input placeholder="确认新密码" v-model="forgetFrom.newPassword" type="password" maxlength="16" />
          </div>
          <div class="item" style="margin-bottom: 45px !important;">
            <div class="icon clearfix">
              <img src="../assets/images/loginCodeImg.png" />
            </div>
            <input placeholder="请输入验证码" class="iptLen" v-model="forgetFrom.passcode" maxlength="4" />
            <div class="codeImg finger">
              <img :src="codeUrl" @click="get_code" alt />
            </div>
          </div>
          <div class="item loginBtn finger" @click="login"></div>
        </div>
      </div>
    </div>
    <div class="btmDiv">
      <div class="leftContent">
        <p style="color: #333; font-size: 16px; line-height: 20px;">为了您更好的使用体验</p>
        <p style="color: #1944c4; font-size: 24px; font-weight: 400;">
          <img src="../assets/images/ico1.png" alt style="vertical-align: middle; width: 22px; height: 22px;" />
          推荐使用谷歌浏览器！
        </p>
      </div>
      <div class="rightContent">
        <p class="down_img">
          <img style="width: 60px; height: 60px;" src="../assets/images/chorme1.png" alt />
        </p>
        <div class="contentText" @click="downChrome">
          <p style="font-size: 20px;" class="text">谷歌浏览器</p>
          <p class="img">
            点击下载
            <img src="../assets/images/download1.png" alt style="margin-left: 5px; vertical-align: text-bottom;" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
export default {
  name: 'newLogin',
  data() {
    return {
      codeUrl: '',
      check: false,
      showDiv: 1,
      loginForm: {
        userName: '',
        password: '',
        passcode: '',
      },
      forgetFrom: {
        userName: '',
        password: '',
        newPassword: '',
        passcode: '',
      },
    }
  },
  mounted() {
    this.nowYear = new Date().getFullYear()
    this.check = Cookies.get('check')
    if (this.check) {
      this.loginForm.userName = Cookies.get('erpManageUserName') || ''
    }
    this.get_code()
    this.autoCode()
  },
  methods: {
    login() {
      let that = this
      if (!that.loginForm.userName) {
        that.$Message.warning('账号不能为空')
        return
      }
      if (!that.loginForm.password) {
        that.$Message.warning('密码不能为空')
        return
      }
      if (!that.loginForm.passcode) {
        that.$Message.warning('验证码不能为空')
        return
      }
      that.$http.get(that.$api.systemRsa).then(res => {
        let loginPasswd = that.$getRsaCode(res.data, that.loginForm.password)
        let data = {
          username: that.loginForm.userName,
          password: loginPasswd,
          image_code: that.loginForm.passcode,
          company_type: 'MANAGE',
        }
        that.$http
          .post(this.$api.manageLogin, data, true)
          .then(res => {
            localStorage.setItem('RoleId', res.userInfo.user_role[0])
            if (res.status) {
              if (that.check) {
                Cookies.set('erpManageUserName', that.loginForm.userName)
              }
              that.$store.commit('setUserInfo', res.userInfo)
              let userRole = res.userInfo.user_role[0]
              window.localStorage.setItem('userRole', userRole)
              localStorage.setItem('userRoleList', JSON.stringify(res.userInfo.user_role))
              let username = res.userInfo.user_name
              window.localStorage.setItem('username', username)
              clearTimeout(that.timer)
              that.$Message.success('登录成功')
              that.$store.commit('resetLogin', 1)
              that.$router.push({
                path: '/main',
              })
            }
          })
          .catch(res => {
            clearInterval(that.timer)
            that.get_code()
            that.autoCode()
          })
      })
    },
    forgetPsd() {
      this.showDiv = 2
    },
    checkClick() {
      this.check = !this.check
      Cookies.set('check', this.check)
      // this.$refs.loginForm.resetFields()
    },
    // 自动刷新二维码
    autoCode() {
      let _this = this
      this.timer = setInterval(function () {
        _this.get_code()
      }, 60000)
    },
    // 获取验证码
    get_code() {
      this.codeUrl = null
      this.loginForm.passcode = ''
      this.forgetFrom.passcode = ''
      this.$http.get(this.$api.getPicAuthCode).then(res => {
        this.codeUrl = res.data
      })
    },
    downChrome() {
      window.open('https://www.google.cn/chrome/')
    },
  },
  destroyed() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped lang="less">
.iptLen {
  width: 200px;
}
.loginDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topDiv {
    flex: 1;
    background: url('../assets/images/login_bg.png');
    background-size: 100%;
    position: relative;
    min-height: 560px;
    overflow-y: auto !important;
    .forGetDiv {
      position: absolute;
      right: 219px;
      top: 223px;
      width: 455px;
      height: 518px;
      /*background: url('../assets/images/login_card2.png') no-repeat !important;*/
      .init {
        position: relative;
        z-index: 99;
        padding: 110px 44px 0 44px !important;
      }
    }
    .loginFormDiv {
      position: absolute;
      right: 219px;
      top: 223px;
      width: 455px;
      height: 518px;
      /*background: url('../assets/images/login_card.png') no-repeat;*/
      .bgImg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .txt {
        width: 100%;
        font-size: 20px;
        height: 18px;
        line-height: 18px;
        font-weight: 400;
        color: rgba(255, 232, 232, 1);
        text-align: center;
        margin-top: 20px;
        .txtSpan {
          display: inline-block;
          color: rgba(255, 232, 232, 1);
          border-bottom: 1px solid rgba(255, 232, 232, 1);
          cursor: pointer;
        }
      }
      .init {
        padding: 110px 44px 0 44px;
        position: relative;
        left: 0;
        top: 0;
        z-index: 999;
        .item {
          width: 100%;
          height: 50px;
          border: 2px solid #a1a6fe;
          margin-bottom: 25px;
          display: flex;
          overflow: hidden;
          .icon {
            width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 2px solid #a1a6fe;
          }
          .codeImg {
            width: 110px;
            height: 100%;
            background: #fff;
            img {
              width: 100%;
              height: 100%;
            }
          }
          input {
            flex: 1;
            background: none;
            border: none;
            padding-left: 17px;
            color: #e8eaff;
            font-size: 20px;
            outline: none;
          }
          input::-webkit-input-placeholder {
            color: #e8eaff;
          }
        }
        .loginBtn {
          border: none !important;
        }
        .choiceBox {
          display: flex;
          justify-content: space-between;
          color: #e8eaff !important;
          border: none !important;
          margin-bottom: 0 !important;
          img {
            width: 23px;
            height: 23px;
            vertical-align: middle;
            margin-right: 10px;
          }
          span {
            vertical-align: middle;
            display: inline-block;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-shadow: 0px 2px 11px rgba(255, 255, 255, 0.08);

            cursor: pointer;
          }
        }
      }
    }
    .leftlogo {
      position: absolute;
      left: 305px;
      top: 45%;
      width: 549px;
      height: 244px;
    }
  }
  .btmDiv {
    height: 120px;
    min-height: 120px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .rightContent {
      display: flex;
      justify-content: flex-start;
      .contentText {
        margin-left: 20px;
        .text {
          color: #1944c4;
          line-height: 32px;
          font-weight: bold;
          font-size: 17px;
        }
        .img {
          width: 100px;
          height: 30px;
          background: rgba(255, 255, 255, 1);
          border-radius: 1px;
          opacity: 0.4;
          border: 1px solid rgba(102, 125, 193, 1);
          font-size: 15px;
          font-weight: 300;

          display: flex;
          align-items: center;
          justify-content: center;
          color: rgba(25, 68, 196, 1);
          cursor: pointer;
          img {
            width: 8px;
            height: 12px;
            // vertical-align: middle;
          }
        }
      }
    }
  }
}
.beian {
  display: inline-block;
  color: #fff;
  margin-top: 150px;
  margin-left: 150px;
  font-size: 16px;
}
</style>
